<template>
  <div>
    <div class="row">
      <div class="col-3">
        <div class="card px-4 py-5 rounded-lg">
          <div class="text-center social-large">
            <i class="uil uil-facebook"></i>
          </div>
          <div class="text-center font-size-20">Facebook Image</div>
          <div class="mt-2 text-center">
            Design the image that will be posted to Facebook.
          </div>
          <div class="text-center mt-4">
            <a href="/business/review-image-editor/facebook" class="btn btn-primary">Edit</a>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card px-4 py-5 rounded-lg">
          <div class="text-center social-large">
            <i class="uil uil-instagram"></i>
          </div>
          <div class="text-center font-size-20">Instagram Image</div>
          <div class="mt-2 text-center">
            Design the image that will be posted to Instagram.
          </div>
          <div class="text-center mt-4">
            <a href="/business/review-image-editor/instagram" class="btn btn-primary">Edit</a>
          </div>
        </div>
      </div>
`      <div v-if="user.has_premium_plan" class="col-3">
        <div class="card px-4 py-5 rounded-lg">
          <div class="text-center social-large">
            <i class="uil uil-google"></i>
          </div>
          <div class="text-center font-size-20">Google Business Profile</div>
          <div class="mt-2 text-center">
            Design the image that will be posted to GBP.
          </div>
          <div class="text-center mt-4">
            <a href="/business/review-image-editor/gbp" class="btn btn-primary">Edit</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  components:{

  },
  data(){
    return {
      user:null
    }
  },
  computed:{
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
  },
  methods:{
  }
}
</script>
<style lang="scss" scoped>
  .social-large{
    font-size: 48px;
  }
</style>
